import { Vue, Component } from 'vue-property-decorator'

function initializePermissionStore (): void {
  localStorage.setItem('permissions', JSON.stringify([]))
}

function getPermissionList (): string[] {
  const wsPerms = localStorage.getItem('permissions')
  if (wsPerms === null) {
    initializePermissionStore()
  }

  let permissions = [] as string[]
  try {
    permissions = JSON.parse(wsPerms || '') as string[]
  } catch {
    initializePermissionStore()
  }
  if (!Array.isArray(permissions)) {
    initializePermissionStore()
  }

  return permissions
}

function setPermission (key: string): void {
  const permissions = getPermissionList()

  if (permissions.includes(key)) {
    return
  }
  permissions.push(key)

  localStorage.setItem('permissions', JSON.stringify(permissions))
}

export function clearPermission (): void {
  localStorage.removeItem('permissions')
}

export function hasPermission (key: string): boolean {
  return getPermissionList().includes(key)
}

export function addPermission (key: string): void {
  setPermission(key)
}

export function PermissionRequiredMixin (key: string): any {
  @Component
  class permissionRequiredMixin extends Vue {
    mounted (): void {
      if (hasPermission(key)) {
        return this.permissionAccepted()
      }
      return this.permissionDenied()
    }

    private permissionAccepted (): void {
      // noop currently
    }

    private permissionDenied (): void {
      this.$router.replace({ name: 'forbidden' })
    }
  }

  return permissionRequiredMixin
}
