
import { Vue, Component } from 'vue-property-decorator'

import { hasPermission } from '@/mixins/permission'

@Component
export default class App extends Vue {
  private drawer = false

  get navigationList () : Array<Record<string, unknown>> {
    const _navigationList = [
      {
        title: 'Home',
        icon: 'mdi-home',
        to: { path: '/' }
      },
      {
        title: 'AS Path Regex',
        icon: 'mdi-map-marker-path',
        to: { path: '/aspath-regex' }
      },
      {
        title: 'IP Calculator',
        icon: 'mdi-stop',
        to: { path: '/ipcalc' }
      },
      {
        title: 'API Document',
        icon: 'mdi-file-document',
        to: { path: '/doc/api' },
        permissionKey: '20210629'
      }
    ]

    const l = [] as Array<Record<string, unknown>>

    for (const e of _navigationList) {
      if (e.permissionKey === undefined || e.permissionKey === '') {
        l.push(e)
        continue
      }
      if (hasPermission(e.permissionKey)) {
        l.push(e)
        continue
      }
    }

    return l
  }

  get titleAnchor () : string {
    return process.env.VUE_APP_GIT_DESCRIPTION
  }
}
